import styled, { css } from 'styled-components';

const BodyContainerSC = styled.section`
	display: flex;
	flex-direction: column;
	flex: 1;
	text-align: center;
	padding: 0;
	width: 100%;
	min-height: 400px;
	height: 100%;
	justify-content: center;
	align-items: center;
	gap: 24px;
	> div {
		margin: 0 !important;
		&,
		.subtitle {
			color: color-mix(
				in srgb,
				var(--color-text-onSurface)
					var(--opacity-emphasis-surface-medium),
				transparent
			);
		}
	}
	> a {
		margin-top: 10px;
	}
`;

const NotFoundContainerSC = styled.main`
	display: flex;
	flex-direction: column;
	height: 100vh;
`;

export { BodyContainerSC, NotFoundContainerSC };
