'use client';
import { Title } from 'ui-library/components/atoms/FontScale';
import { BodyContainerSC } from './style';
import { Link } from 'ui-library/components/atoms/Link';
import { useTranslations } from 'next-intl';
import { base64Encode } from 'statics/lib/base64Encoder';

export default function NotFoundBody() {
	const t = useTranslations();

	return (
		<BodyContainerSC>
			<Title
				className={''}
				size={'XXL'}
				variant={'light'}
				headTag={'h1'}>
				404
			</Title>
			<Title
				className="subtitle"
				size={'L'}
				variant={'black'}
				headTag={'h2'}>
				{t(base64Encode('The page was not found'))}
			</Title>
			<Link variant={'cta'} href={'/home'}>
				{t(base64Encode('Back to home'))}
			</Link>
		</BodyContainerSC>
	);
}
