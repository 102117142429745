'use client';
import React from 'react';
import { SectionProps } from 'ui-library/components/layout/Section/types';
import NotFoundBody from './NotFoundBody';
import { NotFoundContainerSC } from './style';
import { Section } from 'ui-library/components/layout/Section';

const filterByString = (arr: SectionProps[], element: string) =>
	arr.filter((el: any) => el.title === element);

export function NotFoundComponent({
	baseSection,
}: {
	baseSection: SectionProps[];
}) {
	const headerSection = filterByString(
		baseSection,
		'header section',
	)[0];
	const navbarSection = filterByString(
		baseSection,
		'navbar section',
	)[0];
	const footerSection = filterByString(
		baseSection,
		'section footer',
	)[0];

	return (
		<NotFoundContainerSC>
			<Section {...headerSection} />
			<Section {...navbarSection} />
			<NotFoundBody />
			<Section {...footerSection} />
		</NotFoundContainerSC>
	);
}
